import React from 'react';
import './Promotion.scss';

const Promotion = () => {

    return (
        <div className="promotion">
            <img src="/Promotion/Promotion.jpg"></img>
        </div>
    );
};

export default Promotion;